// 要使用vite的image assert需要使用此function取得
const GetImageUrl = (path) => {
  return new URL(`/images/${path}`, import.meta.url).href;
};

const HandleErrorResponse = (e, callback = null) => {
  if (e.response && e.response.status && e.response.status === 401) {
    window.location.href = "/";
    return null;
  } else if (e.response && e.response.status && e.response.status === 422) {
    if (callback) {
      callback();
    }

    return e.response.data?.message;
  } else {
    return "請聯絡管理人員(errcode 01)";
  }
}
  
// 四捨五入
const roundDecimal = function (val, precision) {
  return Math.round(Math.round(val * Math.pow(10, (precision || 0) + 1)) / 10) / Math.pow(10, (precision || 0));
}

// ramgom ID
function MakeID(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}
  
export {
  GetImageUrl,
  roundDecimal,
  MakeID,
  HandleErrorResponse,
}
