const Debounce = (func, delay = 200) => {
    // 用來存定時器 ID 的變數
    let timeout = null;
    
    // 回傳的匿名函式可以被多次的呼叫
    // 呼叫時能接收多個引數來使用
    return (...args) => {
      const later = () => {
        // 註銷定時器
        timeout = null;
        // 正式執行 func, 並帶入接收到的所有引數
        func(...args);
      };
      
      // 匿名函式每次被執行時, 都先取消（如果有）前一次的定時器
      // 這能確保我們都是用最新的定時器在倒數
      clearTimeout(timeout);
      
      // 設定定時器, 並將定時器 ID 存起來供未來查詢
      timeout = setTimeout(later, delay);
    };
  };
  
  export {Debounce}